'use client'

import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { LINKS } from 'constants/links'
import { useLocalizedPath } from 'hooks/i18n/useLocalizedPath'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { LoginForm } from './form/LoginForm'

export const Login: FC = () => {
  const { t, i18n } = useTranslation(['login'])
  const getLocalizedPath = useLocalizedPath(i18n.language)

  return (
    <>
      <Box marginBottom={3}>
        <LoginForm />
      </Box>

      <Stack gap={1} direction="row" justifyContent="space-between">
        <Typography variant="body2">
          <Link href={getLocalizedPath(LINKS.register)} underline="hover">
            {t('login:registerLink')}
          </Link>
        </Typography>
        <Typography variant="body2">
          <Link href={getLocalizedPath(LINKS.resetPassword)} underline="hover">
            {t('login:resetPasswordLink')}
          </Link>
        </Typography>
      </Stack>
    </>
  )
}
