import { setCookie } from 'cookies-next'
import { OptionsType } from 'cookies-next/lib/types'
import { useDefaultZodErrorMap } from 'utils/validations'
import { loginSchema } from 'utils/validations/loginSchema'
import { infer as zodInfer } from 'zod'

export function useLoginFormValidationSchema() {
  const defaultErrorMap = useDefaultZodErrorMap()
  return { schema: loginSchema, errorMap: defaultErrorMap }
}

export type LoginFormValues = zodInfer<
  ReturnType<typeof useLoginFormValidationSchema>['schema']
>

const getCookieOptions = (saveLocalCookie: boolean): OptionsType => ({
  httpOnly: false, // TODO: increase security when all apps run on Next.js
  sameSite: 'lax',
  path: '/',
  domain: saveLocalCookie ? 'localhost' : '.dotu.cz'
})

export const saveJwtCookies = async (
  accessToken: string,
  refreshToken: string,
  previewParam: boolean
) => {
  const accessKey = previewParam
    ? process.env.NEXT_PUBLIC_PREV_JWT_ACCESS_KEY
    : process.env.NEXT_PUBLIC_JWT_ACCESS_KEY
  const refreshKey = previewParam
    ? process.env.NEXT_PUBLIC_PREV_JWT_REFRESH_KEY
    : process.env.NEXT_PUBLIC_JWT_REFRESH_KEY

  const isDeployedApp = process.env.NODE_ENV === 'production'
  const saveLocalCookie = !isDeployedApp && !previewParam

  if (!accessKey) {
    throw new Error('Missing JWT_ACCESS_KEY env variable')
  }

  if (!refreshKey) {
    throw new Error('Missing JWT_REFRESH_KEY env variable')
  }

  console.debug(
    `Saving JWT cookies for ${saveLocalCookie ? 'local' : 'deployed'} app`
  )

  const cookieOptions = getCookieOptions(saveLocalCookie)

  if (!isDeployedApp) {
    console.debug('accessKey', accessKey)
    console.debug('refreshKey', refreshKey)
    console.debug('cookieOptions', cookieOptions)
  }

  setCookie(accessKey, accessToken, cookieOptions)
  setCookie(refreshKey, refreshToken, cookieOptions)
}
