'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import Stack from '@mui/material/Stack'
import { Input } from 'components/form/Input'
import { DEFAULT_FORM_VALIDATION_MODE } from 'constants/forms'
import { PREVIEW_PARAM } from 'constants/params'
import { useRouter, useSearchParams } from 'next/navigation'
import { FC } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FORM_GAP } from 'styles/spacing'
import {
  LoginFormValues,
  saveJwtCookies,
  useLoginFormValidationSchema
} from './LoginForm.utils'
import { LoginFormButton } from './LoginFormButton'
import { login } from './actions/login'

export const LoginForm: FC = () => {
  const { t } = useTranslation(['login', 'common'])

  const router = useRouter()

  const searchParams = useSearchParams()

  const { schema, errorMap } = useLoginFormValidationSchema()
  const form = useForm<LoginFormValues>({
    resolver: zodResolver(schema, { errorMap }),
    mode: DEFAULT_FORM_VALIDATION_MODE
  })

  const action = form.handleSubmit(async values => {
    const response = await login({
      ...values,
      previewUrl: searchParams.get(PREVIEW_PARAM)
    })

    if (!response.success) {
      toast.error(t('login:error'))
    } else {
      saveJwtCookies(
        response.accessToken,
        response.refreshToken,
        Boolean(searchParams.get(PREVIEW_PARAM))
      )

      router.push(response.redirect)
    }
  }) as () => void

  return (
    <FormProvider {...form}>
      <form action={action}>
        <Stack gap={FORM_GAP}>
          <Controller
            name="email"
            control={form.control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <Input
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                type="email"
                label={t('common:fields.email')}
                autoComplete="email"
                required
                sx={{
                  'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
                    {
                      WebkitBoxShadow: '0 0 0 100px #fffbf7 inset !important'
                    }
                }}
              />
            )}
          />

          <Controller
            name="password"
            control={form.control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <Input
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                type="password"
                label={t('common:fields.password')}
                autoComplete="password"
                required
                sx={{
                  'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
                    {
                      WebkitBoxShadow: '0 0 0 100px #fffbf7 inset !important'
                    }
                }}
              />
            )}
          />

          <LoginFormButton />
        </Stack>
      </form>
    </FormProvider>
  )
}
