'use client'

import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { FC } from 'react'
import { useFormStatus } from 'react-dom'
import { useTranslation } from 'react-i18next'

export const LoginFormButton: FC = () => {
  const { t } = useTranslation(['login'])
  const { pending } = useFormStatus()

  return (
    <Button variant="contained" size="large" type="submit" disabled={pending}>
      {pending ? <CircularProgress size={16} /> : t('login:submit')}
    </Button>
  )
}
